import mapValues from 'lodash/mapValues'
import React, { useRef, useState } from 'react'
import uuid from 'uuid-random'
import { BeforeCapture, DragDropContext, Draggable, DraggableChildrenFn, Droppable } from 'react-beautiful-dnd'

import Divider from 'components/divider/Divider'
import FieldArray, { FieldArrayChildrenProps, useFieldArray } from 'components/form/FieldArray'
import Flex from 'components/layout/Flex'
import FormBlock from 'components/blocks/FormBlock'
import GenericObjectRender from 'components/displayTypes/GenericObjectRenderer'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import { DRAWER_HEIGHT_CLOSED_SMALL } from 'components/blocks/DrawerBlock'
import { Resource, useOperationsListQuery, useResourceQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { fieldProps } from './fieldProps'
import type { TextInputProps } from 'components/inputs/TextInput'
import type { ViewProps } from 'components/views'

type NestedRelationsFieldProps = Omit<TextInputProps, 'input' | 'meta'> & fieldProps<'text'> & {
  resourceId?: string
}

type FieldData = any

type Params = {
  resource: Resource,
  initialValues: any,
  onSubmit: (values: any) => void
}

function AddNestedRelationView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { resource, initialValues, onSubmit } = params
  const isUpdating = Boolean(initialValues?.id)
  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)
  const title = resource.name

  const { data, loading, error } = useOperationsListQuery({
    variables: {
      filter: {
        resourceId: {
          eq: resource.id
        },
        behaviorMethod: {
          eq: 'CREATE'
        }
      }
    }
  })

  const handleSubmit = (values: any) => {
    onSubmit(values)
    onRequestClose()
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Footer, Body }) => (
        <>
          <Header title={isUpdating ? `Update ${title}` : `Add ${title}`} onCloseClick={onRequestClose} />
          <Body>
            <SectionLoader loading={loading} error={error} data={data?.operationsList}>
              <FormBlock
                initialValues={initialValues}
                operationId={data?.operationsList[0]?.id}
                resourceId={resource.id}
                isTranslatable={resource.isTranslatable}
                asFragment
                footerEl={footerEl}
                onSubmit={handleSubmit}
              />
            </SectionLoader>
          </Body>
          <Footer>
            <Footer.Right ref={setFooterEl} />
          </Footer>
        </>
      )}
    </View>
  )
}
const NestedRelationsField = ({
  label,
  name,
  helpText,
  isTranslatable,
  settings,
  shouldValidate,
  resourceId
}: NestedRelationsFieldProps) => {
  const { resource_id: relatedResourceId } = settings as any
  const { data, loading, error } = useResourceQuery({
    variables: {
      id: relatedResourceId
    },
    skip: !relatedResourceId
  })

  const { openView } = useViewDispatch()

  const onAdd = () => {
    openView({
      component: AddNestedRelationView,
      params: {
        resource: data?.resource as Resource,
        initialValues: {},
        onSubmit: (values) => {
          fieldsRef.current?.fields.push(
            resourceId
              ? values.arguments
              : mapValues(values.arguments, 'en_US')
          )
        }
      },
      style: 'PANEL'
    })
  }

  const onEdit = (index: number) => {
    openView({
      component: AddNestedRelationView,
      params: {
        resource: data?.resource as Resource,
        initialValues: mapValues(
          fieldsRef.current?.fields.value[index],
          (value) => ({ en_US: value })
        ),
        onSubmit: (values) => {
          fieldsRef.current?.fields.update(
            index,
            resourceId
              ? values.arguments
              : mapValues(values.arguments, 'en_US')
          )
        }
      },
      style: 'PANEL'
    })
  }

  const fieldsRef = useRef<FieldArrayChildrenProps<FieldData>>()
  useFieldArray({ name, fieldsRef, settings, shouldValidate })
  const droppableId = useRef(uuid())

  // To ensure that drawer placeholder height is DRAWER_HEIGHT_CLOSED_SMALL
  // even if the drawer being dragged is open
  const onBeforeCapture = ({ draggableId }: BeforeCapture) => {
    const element = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`) as HTMLElement

    element.style.setProperty('height', `${DRAWER_HEIGHT_CLOSED_SMALL}px`, 'important') //
  }
  const onDragEnd = useReorderFieldArray(fieldsRef)

  const renderDraggableChildren: DraggableChildrenFn = (provided, snapshot, rubric) => (
    <Flex
      gap={8}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <Flex alignItems="center" css={{ height: 45 }}>
        <Icon name="drag-alt" color="dark500" />
      </Flex>
      <Flex direction="column" grow={1}>
        <GenericObjectRender
          displaySettings={{ fontWeight: 'semibold' }}
          data={fieldsRef.current?.fields.value[rubric.source.index]}
        />
      </Flex>
      <Flex alignItems="center" css={{ height: 45 }} gap={4}>
        <IconButton name="edit" description="Edit" onClick={() => onEdit(rubric.source.index)} />
        <IconButton name="trash" description="Delete" onClick={() => fieldsRef.current?.fields.remove(rubric.source.index)} />
      </Flex>
    </Flex>
  )

  return (
    <>
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          {label}{settings?.checkRequired && <span> *</span>}
        </Text>
        <Flex gap={8} alignItems="center">
          {isTranslatable && (
          <Flex alignItems="center" alignSelf="stretch" gap={12}>
            <Divider variant="ruler" orientation="vertical" color="light700" />
            <Icon size={12} name="translate" color="dark200" />
          </Flex>
          )}
          {relatedResourceId && (
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={onAdd}
              mode="distinct"
              disabled={!relatedResourceId}
            >
              Add new
            </TextLink>
          )}
        </Flex>
      </Flex>
      <SectionLoader data={data} loading={loading} error={error} empty={{ title: 'No Resource mapped as Relationship.' }}>
        {helpText && <InputHelpText helpText={helpText} />}
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
          <Droppable
            renderClone={renderDraggableChildren}
            droppableId={droppableId.current}
          >
            {(droppableProvided) => (
              <Flex
                direction="column"
                gap={2}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <FieldArray
                  name={name}
                  fieldsRef={fieldsRef}
                  settings={settings}
                >
                  {({ keys }) => keys.map((key, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={key}
                      index={index}
                      key={key}
                    >
                      {renderDraggableChildren}
                    </Draggable>
                  ))}
                </FieldArray>
                {droppableProvided.placeholder}
                <div />
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </SectionLoader>
    </>
  )
}

export default NestedRelationsField
