import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'
import React from 'react'

import Icon, { IconProps } from 'components/icons/Icon'
import { css } from 'styles/stitches'
import type { AppTechnology } from 'generated/schema'

const DEFAULT_COLORS = {
  billing: '#4259c1',
  bridge: '#769eeb',
  // cabinet: '#24b68e',
  cms: '#6edde2',
  core: '#755ff2',
  'account-center': '#4259c1',
  experimentation: '#fa789b',
  messaging: '#f25c5d',
  automation: '#67ebb4',
  analytics: '#ed81e5',
  'api-hub': '#76d94a',
  'database-hub': '#ffca52',
  'ai-hub': '#5ec5f0',
  planner: '#6b79bc'
}

const INTERNAL_APP_IDENTIFIERS = Object.keys(DEFAULT_COLORS)

type AppIconIdentifier = keyof typeof DEFAULT_COLORS

type AppIconProps = Omit<IconProps, 'name'> & {
  icon?: string,
  identifier: AppIconIdentifier,
  isCustomApp?: boolean,
  isProject?: boolean,
  technology?: AppTechnology
}

const appIconClass = (identifier: AppIconIdentifier) => css({
  color: DEFAULT_COLORS[identifier] || 'dark500'
})

const AppIcon = React.forwardRef<HTMLElement, AppIconProps>(
  ({ className, icon, identifier, isCustomApp, isProject, technology, ...other }, ref) => {
    let reqContext
    let iconName = `app-${identifier}`

    if (Object.keys(DEFAULT_COLORS).indexOf(identifier) === -1) {
      iconName = 'app-bridge'
    }

    if (isCustomApp) { iconName = 'app-custom' }

    if (isProject && technology && !icon) {
      iconName = `${kebabCase(technology)}-colored`
      reqContext = require.context('assets/images/technologies', true, /\.svg$/)
    }

    return (
      <Icon
        {...other}
        className={classNames({ [appIconClass(identifier)]: true }, className)}
        name={icon || iconName}
        ref={ref}
        reqContext={reqContext}
      />
    )
  }
)

AppIcon.displayName = 'AppIcon'

export type { AppIconIdentifier }

export { INTERNAL_APP_IDENTIFIERS }

export default AppIcon
