/* eslint-disable camelcase */
import arrayMutators from 'final-form-arrays'
import get from 'lodash/get'
import React, { Suspense, useMemo } from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator } from 'final-form'

import AttributeModel from 'models/Attribute'
import Button from 'components/buttons/Button'
import componentLoader from 'lib/componentLoader'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Loader from 'components/loaders/Loader'
import pascalCase from 'lib/pascalCase'
import SelectInput from 'components/inputs/SelectInput'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import TextField from 'components/contentEditors/generic/fields/TextField'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useGetAllowedFieldTypes from 'hooks/useGetAllowedFieldTypes'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { css } from 'styles/stitches'
import { FieldIdentifier } from 'models/Field'
import { SidePaneFooter } from 'components/sidePane'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { FieldType } from 'generated/schema'

type Params = ViewParams[Views.ADD_FIELD]

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<any>

const decorators = [
  setIdentifier
]

const classes = {
  wrapper: css({
    marginBottom: 30
  })
}
const getFieldViewName = (fieldType: string) => {
  if (!fieldType) return ''

  const identifier = fieldType.replace('input', 'field')

  // text-field -> TextFieldView
  return `${pascalCase(identifier)}View`
}

const FieldTypeConfig = () => {
  const { values } = useFormState()
  const { change } = useForm()

  const fieldType = get(values, 'field_type')
  const fieldViewName = getFieldViewName(fieldType)
  const fieldViewFileName = pascalCase(fieldViewName)

  const getAllowedFieldTypes = useGetAllowedFieldTypes()

  const {
    fieldTypes: allowedFieldTypes, loading: loadingFieldTypes
  } = getAllowedFieldTypes()

  const defaultFieldType = FieldIdentifier.TEXT

  const FieldTypeSettings = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Settings }))
  ), [ fieldViewFileName ])

  return (
    <Flex direction="column" gap={16}>
      <Flex direction="column" gap={6}>
        <Text fontSize={14} fontWeight="bold" textTransform="uppercase">Field Type</Text>
        <Text color="dark600" fontSize={12}>Choose how this field gets rendered in <b>Forms</b>.</Text>
      </Flex>
      <FormField
        alwaysDirty
        component={SelectInput}
        name="field_type"
        size="small"
        // @ts-ignore
        options={allowedFieldTypes.concat({ identifier: FieldIdentifier.NESTED_RELATIONS, name: 'Nested Relations', icon: 'data-list' })}
        loading={loadingFieldTypes}
        labelKey="name"
        valueKey="identifier"
        defaultValue={defaultFieldType}
        onChange={(option: FieldType) => {
          change('field_type', option.identifier)
          change('field_type_settings', {})
        }}
      />
      {AttributeModel.hasFieldTypeSettings(fieldType) && (
        <Suspense fallback={<Loader loading />}>
          <FormField component="input" type="hidden" name="field_type_settings" alwaysDirty />
          <FieldTypeSettings
            fieldSettingsPrefix="field_type_settings."
          />
        </Suspense>
      )}
    </Flex>
  )
}

const AddFieldView = ({ onClose }: ActiveViewProps) => {
  const {
    dashboardEditorState,
    openDashboardEditorView,
    selectBlock,
    stepBackDashboardEditor,
    updateBlock
  } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues, block, currentIndex, isUpdating } = params! as Params

  const { urn } = useDashboardEditorContextProvider()
  const stepBack = (values?: any) => {
    if (values) {
      let updatedFields

      if (isUpdating) {
        updatedFields = block.properties?.fields.map(
          (c: any, index: number) => {
            if (index === currentIndex) return { ...c, ...values }
            return c
          }
        ) || []
      } else {
        updatedFields = [ ...block.properties?.fields, values ]
      }

      updateBlock(urn, {
        ...block,
        properties: {
          ...block.properties,
          fields: updatedFields
        }
      })
    }

    selectBlock(block.id)
    stepBackDashboardEditor(2)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Edit' : 'Add'} Field`}
        heading="Edit Form Block"
        onClose={onClose}
        onStepBack={stepBack}
      />
      <Form
        initialValues={initialValues}
        decorators={decorators}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={stepBack}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Tabs wrapperClassName={classes.wrapper}>
                  <Tab index={0} label="General" alwaysMounted>
                    <Flex direction="column" gap={16}>
                      <TextField autoFocus shouldValidate settings={{ checkRequired: true }} name="name" label="Name" component={TextInput} size="small" />
                      <TextField shouldValidate settings={{ checkRequired: true }} name="identifier" label="Identifier" component={TextInput} size="small" />
                    </Flex>
                  </Tab>
                  <Tab index={1} label="Appearance" alwaysMounted>
                    <FieldTypeConfig />
                  </Tab>
                </Tabs>
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default AddFieldView
