import compact from 'lodash/compact'
import React, { Fragment, Suspense, useContext, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { DragOverlay, useDroppable } from '@dnd-kit/core'
import { DocumentNode, useLazyQuery } from '@apollo/client'
import { ErrorBoundary } from 'react-error-boundary'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import * as mixins from 'styles/mixins'
import * as queries from 'generated/schema' // Todo lazy import()
import AddResourceItemView from 'components/views/AddResourceItemView'
import BaseLink from 'components/links/BaseLink'
import Block from 'components/blocks/Block'
import blocks from 'components/blocks'
import blockWrappers from 'components/blocks/wrappers'
import componentLoader from 'lib/componentLoader'
import DashboardContext from 'components/contexts/DashboardContext'
import DashboardQueryContext from 'components/contexts/DashboardQueryContext'
import DashboardViewContext, { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import NotFoundPage from 'components/pages/NotFoundPage'
import PageLoader from 'components/loaders/PageLoader'
import reportError from 'lib/reportError'
import Text from 'components/typography/Text'
import TitleBlock from 'components/blocks/TitleBlock'
import useComponentDidMount from 'hooks/useComponentDidMount'
import useDashboard, { Element, Operation } from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import useSwitcherState from 'hooks/useSwitcherState'
import useViewUrn from 'hooks/useViewUrn'
import { DraggableBlockCard } from 'components/dashboardEditor/AddBlockView'
import { parseAndRenderSync } from 'lib/templater'
import { styled } from 'styles/stitches'
import { useDashboardEditorContextProvider } from 'components/dashboardEditor/DashboardEditorProvider'
import { useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import { useViewDispatch } from 'hooks/useViewContext'
import { Views } from 'components/dashboardEditor/constants'
import type { App, Resource } from 'generated/schema'
import type { Color } from 'styles/theme'
import type { CommonFilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import AddBlockButton from 'components/blocks/AddBlockButton'

type DashboardViewComponentProps = {
  appId: string,
  app: App,
  resourceId: string,
  resource?: Resource,
  name: string,
  selectedMenuElement: ComputedMenuElement
}

function LoadQuery(operation: Operation) {
  const {
    type,
    identifier,
    documentName,
    nodeName,
    variables
  } = operation

  const [ parsedVariables, setParsedVariables ] = useState({})
  const { createOperation, operationState, getElementsSelector } = useDashboard()
  const setOperation = useSetRecoilState<Operation>(operationState(identifier))

  const document = queries[`${documentName}Document` as keyof typeof queries] as DocumentNode

  const elementIds: string[] = compact(variables?.filter?.and?.map((variable: CommonFilterType) => {
    const filter = Object.values(variable)[0]
    const value = Object.values(filter)[0]
    if (typeof value === 'string') {
      const isVariable = value?.includes('{{')
      if (value && isVariable) {
        return value.replace('{{', '').replace('}}', '').split('.')[1]
      }
    }
    return ''
  }))

  const elementsData: Element[] = useRecoilValue(getElementsSelector(elementIds))

  useEffect(() => setParsedVariables({
    ...variables,
    filter: {
      ...variables?.filter,
      and: compact(variables?.filter?.and?.map((variable: CommonFilterType) => {
        const [ column, filter ] = Object.entries(variable)[0]
        const [ operation, value ] = Object.entries(filter)[0]
        if (typeof value === 'string') {
          const isVariable = value?.includes('{{')
          if (value && isVariable) {
            const valueAtomIdentifier = value.replace('{{', '').replace('}}', '').split('.')[1]
            const valueAtomData = elementsData.find(
              (data) => data.identifier === valueAtomIdentifier
            )
            const parsedValue = parseAndRenderSync(value, { elements:
              { [valueAtomIdentifier]: valueAtomData } })
            if (parsedValue) {
              return { [column]: { [operation]: parsedValue } }
            } return undefined
          }
        }
        return variable
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [ elementsData ])

  const [ getData, { data, loading, error } ] = useLazyQuery(document, {
    variables: parsedVariables
  })

  useComponentDidMount(() => {
    createOperation(identifier, {
      ...operation,
      variables: parsedVariables,
      loading,
      error
    })
  })

  useEffect(() => {
    if (documentName && document && type !== 'MUTATION') {
      getData()
    }
  }, [ documentName, document, type, getData ])

  useEffect(() => {
    if (data) {
      setOperation((prev) => ({
        ...prev,
        ...operation,
        loading,
        error,
        [identifier]: data[nodeName]
      }))
    }
  }, [ data, loading, error, nodeName, setOperation, identifier, operation ])

  return null
}

type ColorMap = { [key: string]: Color }

const POPOVER_ITEM_ICON_COLORS: ColorMap = {
  normal: 'dark500',
  hover: 'primary300'
}

const POPOVER_ITEM_LABEL_COLORS: ColorMap = {
  normal: 'dark700',
  hover: 'primary300'
}

const StyledPopoverItemIcon = styled(Icon, {
  color: POPOVER_ITEM_ICON_COLORS.normal
})

const StyledPopoverItemLabel = styled(Text, {
  fontSize: 16,
  color: POPOVER_ITEM_LABEL_COLORS.normal
})

const StyledMenuItem = styled(Flex, {
  ...mixins.transition('simple'),

  cursor: 'pointer',
  paddingY: 15,
  paddingX: 25,

  whiteSpace: 'nowrap',
  backgroundColor: 'light100',
  width: '300px',
  borderRadius: 6,
  border: '1px solid light600',

  '&:hover, &:focus': {
    backgroundColor: 'light200',
    cursor: 'pointer',

    [`${StyledPopoverItemIcon}`]: {
      color: POPOVER_ITEM_ICON_COLORS.hover
    },

    [`${StyledPopoverItemLabel}`]: {
      color: POPOVER_ITEM_LABEL_COLORS.hover
    }
  }
})

const MenuItem = ({ icon, text, to, type = 'button', kind, separatorStyle, target, ...props }: any) => {
  if (kind === 'SEPARATOR') return <Divider css={{ gridColumn: '1 / 5' }} color="dark100" spacing={16} variant={separatorStyle.toLowerCase()} />

  if (kind === 'GROUP') {
    return (
      <Text
        css={{
          width: '100%',
          padding: 16,
          gridColumn: '1 / 5'
        }}
        fontSize={12}
        textTransform="uppercase"
      >
        {text}
      </Text>
    )
  }

  return (
    <StyledMenuItem
      as={to ? BaseLink : 'button'}
      type={to ? type : undefined}
      alignItems="center"
      gap={14}
      to={target === 'URL' ? undefined : to}
      href={target === 'URL' ? to : undefined}
      {...props}
    >
      <StyledPopoverItemIcon name={icon} size={24} />
      <StyledPopoverItemLabel fontSize={12} fontWeight="semibold" letterSpacing="condensed" truncate>
        {text}
      </StyledPopoverItemLabel>
    </StyledMenuItem>
  )
}

const SubmenuView = ({ menuElement }: { menuElement: ComputedMenuElement }) => {
  const { openView } = useViewDispatch()
  const { parentIdToMenuElementsMap } = useContext(InternalContext)!
  const { newNonStickyPosition, newStickyPosition } = useMenuElementPositionContext() || {}

  const parentId = menuElement.id
  const { dashboardId } = menuElement

  const { openDashboardEditor } = useContext(DashboardContext)!
  const { openDashboardEditorView } = useDashboard()

  const params = {
    dashboardId,
    placement: menuElement.placement,
    parentId,
    newNonStickyPosition,
    newStickyPosition
  }

  const onAddAppItem = (() => {
    openDashboardEditorView({
      target: Views.ADD_MENU_APP,
      params: {
        initialValues: { parentId, dashboardId }
      }
    })
    openDashboardEditor()
  })

  const onAddResourceItem = (() => {
    openView({
      title: 'Add Resource Menu Item',
      component: AddResourceItemView,
      params: { initialValues: { parentId, dashboardId } },
      style: AddResourceItemView.defaultStyle
    })
  })

  const onAddMenuElement = ((initialValues: Partial<ComputedMenuElement>) => {
    openDashboardEditorView({
      target: Views.ADD_MENU_ELEMENT,
      params: {
        initialValues: {
          ...params,
          ...initialValues
        }
      }
    })
    openDashboardEditor()
  })

  if (parentIdToMenuElementsMap[parentId]?.length > 0) {
    return (
      <>
        <TitleBlock heading={menuElement.name!} />
        <Block width={{ md: '100%' }} direction="column" gap={24}>
          <Grid columns={4} gap={16}>
            {parentIdToMenuElementsMap[parentId].map((menuElement) => (
              <MenuItem
                key={menuElement.id}
                icon={menuElement.icon}
                text={menuElement.name}
                to={menuElement.fullPath}
                kind={menuElement.kind}
                separatorStyle={menuElement.separatorStyle}
                target={menuElement.target}
              />
            ))}
          </Grid>
        </Block>
      </>
    )
  }

  return (
    <>
      <TitleBlock heading={menuElement.name!} />
      <Block width={{ md: '100%' }} alignItems="center" direction="column" gap={24}>
        <Flex alignItems="center" direction="column" gap={12}>
          <Text fontSize={18} fontWeight="bold">This sub menu is currently empty.</Text>
          <Text fontSize={14} color="dark600">Add some menu elements.</Text>
        </Flex>
        <Flex direction="column" gap={8}>
          <MenuItem icon="app" onClick={onAddAppItem} text="App" />
          <MenuItem icon="graph" onClick={onAddResourceItem} text="Resources" />
          <MenuItem
            icon="display-normal"
            onClick={() => onAddMenuElement({
              target: 'VIEW',
              kind: 'ITEM'
            })}
            text="Blank View"
          />
          <MenuItem
            icon="menu-item"
            onClick={() => onAddMenuElement({
              target: 'SUBMENU',
              kind: 'ITEM'
            })}
            text="Sub Menu"
          />
          <MenuItem
            icon="link"
            onClick={() => onAddMenuElement({
              target: 'URL',
              kind: 'ITEM'
            })}
            text="External Link"
          />
          <MenuItem
            icon="graph"
            onClick={() => onAddMenuElement({
              kind: 'GROUP'
            })}
            text="Header"
          />
          <MenuItem
            icon="separator"
            onClick={() => onAddMenuElement({
              kind: 'SEPARATOR',
              separatorStyle: 'RULER'
            })}
            text="Separator"
          />
        </Flex>
      </Block>
    </>
  )
}

type RenderBlockProps = {
  id: string,
  containerId?: string,
  setTitle?: React.Dispatch<React.SetStateAction<string>>,
  footerEl?: HTMLElement | null
}

const BlockComponent = ({
  id,
  containerId,
  setTitle,
  footerEl
}: RenderBlockProps) => {
  const {
    getOperations,
    removeBlock,
    selectBlock,
    blockState,
    updateBlock,
    openDashboardEditorView
  } = useDashboard()

  const {
    editMode,
    openDashboardEditor,
    selectedSideMenuElement,
    selectedTopMenuElement
  } = useContext(DashboardContext)!

  const { activeUrn } = useDashboardViewContext()

  const selectedMenuElement = selectedSideMenuElement || selectedTopMenuElement
  const selectedViewUrn = selectedMenuElement?.viewUrn!

  const block = useRecoilValue(blockState(id))

  const [ upsertView ] = queries.useUpsertViewMutation()

  const handleUpsertBlock = useSubmitHandler(upsertView)

  const onEditBlock = () => {
    selectBlock(block.id)
    openDashboardEditor()
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
  }

  const onRemoveBlock = () => {
    getOperations().then((operations) => {
      removeBlock(activeUrn, block.id).then((blocks) => {
        handleUpsertBlock({
          urn: selectedViewUrn,
          operations: operations.filter(
            (operation: any) => operation.blockId !== block.id
          ) || [],
          blocks
        })
      })
    })
  }

  const onResize = (columns: any[]) => {
    const updatedBlock = {
      ...block,
      properties: {
        ...block.properties,
        columns
      }
    } as any

    return updateBlock(activeUrn, updatedBlock)
      .then((blocks) => getOperations()
        .then((operations) => handleUpsertBlock({
          urn: selectedViewUrn,
          operations,
          blocks
        })))
  }
  const blockHeading = block.properties?.heading
  useEffect(() => {
    const isTitleBlock = block.type === 'TitleBlock'
    let oldTitle: string

    if (isTitleBlock && setTitle) {
      setTitle((value) => {
        oldTitle = value
        return blockHeading
      })

      return () => setTitle(oldTitle)
    }

    return () => {}
  }, [ blockHeading, block.type, setTitle ])

  // We set block as null while deleting to update UI
  if (!block) return null
  const Block = blocks[block.type as keyof typeof blocks]
  const BlockWrapper = blockWrappers[`${block.type}Wrapper` as keyof typeof blockWrappers]
  if (!Block) {
    console.error(`Missing block type: ${block.type}`)
    return null
  }

  const isTitleBlock = block.type === 'TitleBlock'

  if (isTitleBlock && setTitle) {
    return null
  }

  const commonProps = {
    'data-id': block?.id,
    id,
    containerId,
    block,
    hideActionCard: block.type === 'ColumnsBlock' && block.properties.columns?.length,
    position: block.position,
    resizeEnabled: editMode,
    isViewBlock: isTitleBlock,
    onEdit: onEditBlock,
    onRemove: onRemoveBlock,
    onResize,
    width: { md: '100%' },
    footerEl
  }

  if (!BlockWrapper) {
    return (
      <Block
        {...commonProps}
        {...(block.properties as any)}
      />
    )
  }

  return (
    <ErrorBoundary FallbackComponent={() => null} onError={reportError}>
      <BlockWrapper
        {...commonProps}
      />
    </ErrorBoundary>
  )
}

const Dropzone = ({ id }: any) => {
  const { draggingOverBlockIdState, draggedBlockState } = useDashboard()
  const draggingOverBlockId = useRecoilValue(draggingOverBlockIdState)
  const draggedBlock = useRecoilValue(draggedBlockState)

  const { setNodeRef, isOver } = useDroppable({
    id: `${id}__dropzone`
  })

  const isVisible = (isOver || draggingOverBlockId === id) && !!draggedBlock

  return (
    <Flex
      ref={setNodeRef}
      alignItems="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: 32,
        width: '100%',
        marginTop: -16,
        marginBottom: -16,
        opacity: isVisible ? 1 : 0
      }}
    >
      <Flex
        css={{
          width: '100%',
          borderBottom: '2px dashed dark700',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'light100'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )
}

const Overlay = () => {
  const { draggedBlockState } = useDashboard()
  const draggedBlock = useRecoilValue(draggedBlockState)

  return createPortal(
    <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
      {draggedBlock?.type ? (
        <DraggableBlockCard block={draggedBlock} isActive isOverlay />
      ) : null}
    </DragOverlay>,
    document.body
  )
}

function DashboardPage() {
  const {
    resetDashboardEditorStack,
    updateBlock,
    updateBlockProperties,
    updateOperation,
    updateView
  } = useDashboard()
  const {
    selectedSideMenuElement,
    selectedTopMenuElement,
    dashboardLoading
  } = useContext(DashboardContext)!

  const selectedMenuElement = selectedSideMenuElement || selectedTopMenuElement
  const activeUrn = selectedMenuElement?.viewUrn

  const { data: activeView, loading, error } = useViewUrn({ urn: activeUrn })

  const activeApp = activeView?.app
  const activeAppId = activeApp?.id || activeView?.appId
  const activeResourceId = activeView?.resourceId
  const activeViewId = activeView?.id
  const activeBlocks = activeView?.blocks || []
  const resource = activeView?.resource
  const componentPath = activeView?.componentPath
  const isCustomResourceView = activeResourceId && activeBlocks.length
  const { switcher } = useSwitcherState(activeAppId)

  useEffect(() => {
    updateBlockProperties({
      currentEnvironment: switcher.data.environment!
    })
  }, [ switcher.data, updateBlockProperties ])

  const { onUrnChange } = useDashboardEditorContextProvider()
  useEffect(() => {
    let current: string
    onUrnChange((previousUrn) => {
      current = previousUrn
      return activeUrn!
    })
    resetDashboardEditorStack()

    return () => {
      onUrnChange(current)
      resetDashboardEditorStack()
    }
  }, [ activeUrn, onUrnChange, resetDashboardEditorStack ])

  const ViewComponent = useMemo(() => (
    componentPath && !isCustomResourceView
      ? React.lazy(() => componentLoader(`views/${componentPath}`))
      : () => null
  ), [ componentPath, isCustomResourceView ])

  useEffect(() => {
    activeView && updateView(activeUrn!, activeView)
    activeView?.blocks?.map((block) => (
      updateBlock(activeUrn!, block, true)
    ))

    activeView?.operations?.map((operation) => (
      updateOperation(operation)
    ))
  }, [ activeUrn, activeView, updateBlock, updateOperation, updateView ])

  if (selectedMenuElement?.target === 'SUBMENU') {
    return <SubmenuView menuElement={selectedMenuElement} />
  }

  if (!componentPath
    && !activeView
    && !loading
    && !dashboardLoading
  ) {
    return <NotFoundPage fullscreen />
  }

  if (componentPath && !activeBlocks.length) {
    return (
      <DashboardViewContext.Provider
        value={{ activeView: activeView!, activeUrn: activeUrn!, switcher }}
      >
        <Suspense fallback={<PageLoader loading />}>
          <ViewComponent
            key={activeViewId}
            app={activeApp}
            appId={activeAppId}
            resourceId={activeResourceId}
            resource={resource}
            selectedMenuElement={selectedMenuElement}
            name={activeView?.name}
          />
        </Suspense>
      </DashboardViewContext.Provider>
    )
  }

  return (
    <DashboardViewContext.Provider
      value={{ activeView: activeView!, activeUrn: activeUrn!, switcher }}
    >
      <PageLoader
        data={activeView && (activeResourceId && !isCustomResourceView ? resource : activeView)}
        error={error}
        loading={loading || dashboardLoading}
        key={activeViewId}
      >
        <DashboardView
          activeUrn={activeUrn!}
          activeView={activeView!}
        />
      </PageLoader>
    </DashboardViewContext.Provider>
  )
}

function DashboardView({
  setTitle,
  footerEl,
  activeUrn,
  activeView
}: {
  setTitle?: React.Dispatch<React.SetStateAction<string>>,
  footerEl?: HTMLElement | null,
  activeUrn: string,
  activeView: queries.View
}) {
  const {
    blockIds: blockIdsState,
    resetBlockIds,
    resetOperationIds
  } = useDashboard()

  const blockIds = useRecoilValue(blockIdsState(activeUrn!))

  useEffect(() => () => {
    resetBlockIds(activeUrn!)
    resetOperationIds()
  }, [ resetBlockIds, resetOperationIds, activeUrn ])

  /* const [ upsertView ] = queries.useUpsertViewMutation({
    refetchQueries: [ {
      query: queries.ViewDocument,
      variables: { id: activeView?.id }
    } ]
  })

  const handleUpsertBlock = useSubmitHandler(upsertView) */

  const viewState = {}

  const operations = activeView?.operations?.filter((operation) => operation.type === 'QUERY') || []

  return (
    <>
      {operations.map(
        (operation) => <LoadQuery {...operation as Operation} />
      )}
      <DashboardQueryContext.Provider value={viewState}>
        {blockIds.map((blockId, index) => (
          <Fragment key={blockId}>
            <BlockComponent
              key={blockId}
              id={blockId}
              setTitle={setTitle}
              footerEl={footerEl}
            />
            <Dropzone id={blockId} index={index} />
          </Fragment>
        ))}

        <AddBlockButton
          id="ADD_BLOCK"
          alwaysVisible={blockIds.length === 1}
          label="Add Block"
        />

        <Overlay />
      </DashboardQueryContext.Provider>
    </>
  )
}

export {
  DashboardView
}

export default DashboardPage

export {
  BlockComponent,
  LoadQuery
}

export type { DashboardViewComponentProps }
