import React, { useCallback, useLayoutEffect, useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import uuid from 'uuid-random'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import type { RefObject } from 'react'

import DataManagerProvider from 'components/providers/DataManagerProvider'
import Flex from 'components/layout/Flex'
import Pager from 'components/dataWidgets/Pager'
import SectionLoader from 'components/loaders/SectionLoader'
import SummaryBar from 'components/dataWidgets/SummaryBar'
import TableHeader from 'components/dataTable/TableHeader'
import TableRow, { sizeToHeightMap, TableRowProps } from 'components/dataTable/TableRow'
import tableRowSkeletonImage from 'assets/images/table-row-skeleton-colored.svg'
import useVirtual, { VirtualItem } from 'hooks/useVirtual'
import { DATA_TABLE_CELL_COMPACT_HEIGHT, DATA_TABLE_CELL_COZY_HEIGHT } from 'components/dataTable/constants'
import { styled } from 'styles/stitches'
import { useDataManagerContext } from 'hooks/useDataManagerContext'
import type { Column } from 'components/dataTable/types'
import type { DataManagerProviderProps, ID } from 'components/providers/DataManagerProvider'
import type { PagerProps } from 'components/dataWidgets/Pager'
import type { SummaryBarProps } from 'components/dataWidgets/SummaryBar'
import type { LoaderProps } from 'components/loaders/Loader'

const StyledContainer = styled('div', {
  backgroundImage: `url(${tableRowSkeletonImage})`,
  backgroundRepeat: 'no-repeat repeat',
  backgroundSize: 'auto',
  position: 'relative',
  width: '100%'
})

type DEFAULT_ROW_DATA = { id?: ID }

type DataTableProps<T extends DEFAULT_ROW_DATA> =
  PagerProps
  & LoaderProps
  & DataManagerProviderProps<T>
  & Pick<SummaryBarProps<T>, 'batchActions' | 'hideFormulae' | 'hideSelectAll'>
  & {
    columns: Column<T>[],
    containerPadding?: number,
    scrollParentRef?: RefObject<HTMLElement>,
    summaryBarVariant?: SummaryBarProps<any>['variant'],
    rowSize?: TableRowProps['size']
  }

type DataTableBodyProps<T extends DEFAULT_ROW_DATA> = {
  columns: Column<T>[],
  page?: PagerProps['page'],
  pageSize?: PagerProps['pageSize'],
  paginationMode?: PagerProps['paginationMode'],
  totalRows?: PagerProps['totalRows'],
  onChangePage?: PagerProps['onChangePage'],
  onChangePageSize?: PagerProps['onChangePageSize'],
  rowSize?: TableRowProps['size']
}

type TableRowsProps<T> = Pick<DataTableProps<T>, 'data' | 'columns'> & {
  isDraggable: boolean,
  totalRows?: number,
  onChangePage?: PagerProps['onChangePage'],
  onChangePageSize?: PagerProps['onChangePageSize'],
  pageSize?: PagerProps['pageSize'],
  size?: TableRowProps['size']
}

const SimpleTableRows = <T extends DEFAULT_ROW_DATA>({
  data,
  columns,
  isDraggable,
  size = 'cozy',
  pageSize
}: TableRowsProps<T>) => (
  <StyledContainer
    // @ts-ignore
    style={{ height: (pageSize && isDraggable) ? pageSize * sizeToHeightMap[size].height : 'auto' }}
  >
    {data.map((datum: any, index: number) => {
      const key = (datum?.id ?? index).toString()
      if (!isDraggable) {
        return (
          <TableRow
            key={key}
            columns={columns}
            index={index}
            size={size}
          />
        )
      }

      return (
        <Draggable
          disableInteractiveElementBlocking
          draggableId={key}
          index={index}
          key={key}
        >
          {(provided) => (
            <TableRow
              {...provided}
              columns={columns}
              index={index}
              size={size}
            />
          )}
        </Draggable>
      )
    })}
  </StyledContainer>
  )

const VirtualizedRows = <T extends DEFAULT_ROW_DATA>({
  data,
  columns,
  isDraggable,
  totalRows,
  pageSize = 1,
  onChangePage,
  onChangePageSize,
  size
}: TableRowsProps<T>) => {
  const containerParentRef = useRef<HTMLDivElement | null>(null)
  const estimateSize = useCallback(() => (size === 'compact' ? DATA_TABLE_CELL_COMPACT_HEIGHT : DATA_TABLE_CELL_COZY_HEIGHT), [ size ])

  const { totalSize, virtualItems } = useVirtual({
    containerParentRef,
    estimateSize,
    overscan: 5,
    size: totalRows ?? data?.length
  })

  const page = Math.ceil(((virtualItems[virtualItems.length - 1]?.index || 0) + 1) / pageSize)

  // If screen window is larger than current page size, increase page size
  useLayoutEffect(() => {
    if (pageSize < virtualItems.length) {
      onChangePageSize?.(Math.floor((virtualItems.length / 10) + 1) * 10)
    }
  }, [ onChangePageSize, pageSize, virtualItems.length ])

  useLayoutEffect(() => {
    onChangePage?.(page)
  }, [ onChangePage, page ])

  const renderDataListItem = (virtualItem: VirtualItem) => {
    if (!data[virtualItem.index]) {
      return null
    }

    const key = (data[virtualItem.index].id ?? virtualItem.index).toString()

    if (!isDraggable) {
      return (
        <TableRow
          key={key}
          columns={columns}
          index={virtualItem.index}
          start={virtualItem.start}
          size={size}
        />
      )
    }

    return (
      <Draggable
        disableInteractiveElementBlocking
        draggableId={key}
        index={virtualItem.index}
        key={key}
      >
        {(provided) => (
          <TableRow
            {...provided}
            columns={columns}
            index={virtualItem.index}
            start={virtualItem.start}
            size={size}
          />
        )}
      </Draggable>
    )
  }

  return (
    <StyledContainer
      ref={containerParentRef}
      style={{ height: totalSize }}
    >
      {virtualItems.map(renderDataListItem)}
    </StyledContainer>
  )
}

function DataTableBody<T extends DEFAULT_ROW_DATA>({
  columns,
  page,
  pageSize,
  paginationMode,
  totalRows,
  onChangePage,
  onChangePageSize,
  rowSize,
  ...others
}: DataTableBodyProps<T>) {
  const {
    data,
    isDraggable
  } = useDataManagerContext()
  const droppableId = useRef(uuid())

  const Rows = paginationMode !== 'finite' ? VirtualizedRows : SimpleTableRows
  if (!isDraggable) {
    return (
      <Rows
        columns={columns}
        data={data}
        isDraggable={isDraggable}
        totalRows={totalRows}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        pageSize={pageSize}
        size={rowSize}
      />
    )
  }

  return (
    <Droppable
      droppableId={droppableId.current}
      mode={paginationMode !== 'finite' ? 'virtual' : 'standard'}
      renderClone={(provided, _, rubric) => (
        <TableRow
          {...provided}
          columns={columns}
          index={rubric.source.index}
          start={0}
          size={rowSize}
        />
      )}
    >
      {(droppableProvided) => (
        <div
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          {...others}
        >
          <Rows
            columns={columns}
            data={data}
            isDraggable={isDraggable}
            totalRows={totalRows}
            onChangePage={onChangePage}
            pageSize={pageSize}
            size={rowSize}
          />
        </div>
      )}
    </Droppable>
  )
}

const ScrollWrapper = ({ children, containerPadding }: any) => (containerPadding
  ? (
    <Scrollbars
      autoHeight
      autoHeightMax="100%"
      autoHide
      renderTrackHorizontal={(props) => (
        <div
          {...props}
          style={{
            ...props.style,
            right: containerPadding,
            left: containerPadding,
            bottom: 0,
            borderRadius: 6
          }}
        />
      )}
      style={{
        width: 'auto',
        marginLeft: -containerPadding,
        marginRight: -containerPadding
      }}
    >
      {children}
    </Scrollbars>
  ) : children)

function DataTable<T extends DEFAULT_ROW_DATA>({
  actions,
  batchActions,
  columns,
  containerPadding = 0,
  data = [],
  defaultOrder,
  defaultSelection,
  empty,
  error,
  loading,
  hideFormulae,
  hideSelectAll,
  onChangePage,
  onChangePageSize,
  onRowSelect,
  rowSize = 'cozy',
  page,
  pageSize,
  pageSizeOptions,
  paginationMode,
  scrollParentRef,
  selectionHandlerRef,
  selectionMode = 'multiple',
  setOrder,
  summaryBarVariant = 'fixed',
  totalRows: _totalRows,
  onRowDragEnd,

  ...others
}: DataTableProps<T>) {
  const showPager = paginationMode === 'finite'
  const showSummary = !!batchActions?.length
  const dataRef = useRef<any>({})

  if (pageSize && page) {
    dataRef.current[pageSize] = dataRef.current[pageSize] || {}
    dataRef.current[pageSize][page] = data
  }

  // compute totalRows from page, pageSize & data length
  const totalRows = page && pageSize
    ? Math.max(_totalRows ?? page * pageSize, data.length + ((page - 1) * pageSize))
    : _totalRows

  const computedData = paginationMode === 'infinite'
    ? new Array(totalRows).fill(null).map((_, index) => {
      const pageIndex = Math.floor(index / pageSize!) + 1
      return dataRef.current[pageSize!]?.[pageIndex]?.[index % pageSize!]
    }).filter(Boolean) : data

  return (
    <Flex direction="column" gap={24}>
      <DataManagerProvider
        actions={actions}
        data={computedData}
        loading={loading}
        defaultSelection={defaultSelection}
        defaultOrder={defaultOrder}
        onRowSelect={onRowSelect}
        selectionHandlerRef={selectionHandlerRef}
        selectionMode={selectionMode}
        setOrder={setOrder}
        totalRows={totalRows}
        onRowDragEnd={onRowDragEnd}
      >
        <Flex direction="column">
          <ScrollWrapper containerPadding={containerPadding}>
            <div
              style={{
                display: 'inline-block',
                paddingLeft: containerPadding,
                paddingRight: containerPadding,
                minWidth: '100%'
              }}
            >
              <TableHeader columns={columns} />
              {!!computedData.length && (
                <DataTableBody
                  columns={columns}
                  page={page}
                  pageSize={pageSize}
                  paginationMode={paginationMode}
                  totalRows={totalRows}
                  onChangePage={onChangePage}
                  onChangePageSize={onChangePageSize}
                  rowSize={rowSize}
                  {...others}
                />
              )}
            </div>
          </ScrollWrapper>
          <SectionLoader
            data={computedData}
            error={error}
            loading={loading}
            empty={empty}
          />
        </Flex>
        {(showPager || showSummary) && (
        <Flex justifyContent={summaryBarVariant === 'normal' ? 'space-between' : 'center'}>
          {showSummary && (
            <SummaryBar
              batchActions={batchActions}
              variant={summaryBarVariant}
              hideFormulae={hideFormulae}
              hideSelectAll={hideSelectAll}
            />
          )}
          {showPager && (
            <Pager
              data={computedData}
              loading={loading}
              error={error}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
              page={page}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              paginationMode={paginationMode}
              totalRows={totalRows}
            />
          )}
        </Flex>
        )}
      </DataManagerProvider>
    </Flex>
  )
}

export type { DataTableProps }
export default DataTable
