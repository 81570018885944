import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useRecoilValue } from 'recoil'

import * as mixins from 'styles/mixins'
import Block, { BlockProps } from './Block'
import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import JSONParseOr from 'lib/JSONParseOr'
import List from 'components/list/List'
import ListItem from 'components/list/ListItem'
import RelatedResourceRecordsView from 'components/views/graph/RelatedResourceRecordsView'
import SectionLoader from 'components/loaders/SectionLoader'
import Tab from 'components/tabs/Tab'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useExecuteOperationQuery from './wrappers/useExecuteOperationQuery'
import useActiveLocales, { Locale } from 'hooks/useActiveLocales'
import Tabs from 'components/tabs/Tabs'
import { ATTRIBUTES_LIST_LIMIT, RELATIONSHIPS_LIST_LIMIT } from 'models/Resource'
import { ChipRenderer } from 'components/displayTypes/ChipView'
import { CodeRenderer } from 'components/displayTypes/CodeView'
import { CurrencyRenderer } from 'components/displayTypes/CurrencyView'
import { CustomRecord, Relationship, useAttributesListQuery, useInternalFetchRecordQuery, useInternalSummarizeRecordsQuery, useRelationshipsListQuery, useResourceQuery } from 'generated/schema'
import { DateTimeRenderer } from 'components/displayTypes/DateTimeView'
import { DisplayType } from 'models/Attribute'
import { DurationRenderer } from 'components/displayTypes/DurationView'
import { EmbeddedRenderer } from 'components/displayTypes/EmbeddedView'
import { FileRenderer } from 'components/displayTypes/FileView'
import { GenericRenderer } from 'components/displayTypes/GenericView'
import { LinkRenderer } from 'components/displayTypes/LinkView'
import { MediaRenderer } from 'components/displayTypes/MediaView'
import { NumberRenderer } from 'components/displayTypes/Number'
import { safeParseLiquid } from 'lib/templater'
import { PhoneNumberRenderer } from 'components/displayTypes/PhoneNumberView'
import { PlainTextRenderer } from 'components/displayTypes/PlainTextView'
import { ReferenceRenderer } from 'components/displayTypes/ReferenceView'
import { styled } from 'styles/stitches'
import { SwitchRenderer } from 'components/displayTypes/SwitchView'
import { useViewDispatch } from 'hooks/useViewContext'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'

type DetailsBlockProps = BlockProps & {
  asFragment?: boolean,
  resourceId: string,
  recordId?: string,
  record?: any,
  targetEnvironment?: string
}

const TAB_PADDING = 35

const StyledListItem = styled(ListItem, {
  ...mixins.transition('simple'),

  [`${Icon}`]: {
    color: 'dark100'
  },

  '&:hover': {
    [`${Icon}`]: {
      color: 'dark300'
    }
  }
})

const StyledTabsWrapper = styled(Flex, {
  border: '1 solid light700',
  borderWidth: 0.5
})

const StyledTab = styled(Tab, {
  padding: TAB_PADDING,

  variants: {
    background: {
      light: {
        backgroundColor: 'light100'
      },
      none: {}
    }
  }
})

type RelatedListItemProps = {
  record: CustomRecord,
  relationship: Relationship,
  onClick: () => void
}

const RelatedListItem = ({ relationship, record, onClick }: RelatedListItemProps) => {
  const {
    data: { internalSummarizeRecords: { count } = { count: 0 } } = {}
  } = useInternalSummarizeRecordsQuery({
    variables: {
      input: {
        resourceId: relationship.target.id,
        filter: {
          [relationship.targetAttribute.identifier]: {
            eq: record.data[relationship.sourceAttribute.identifier]?.en_US
          }
        }
      }
    }
  })

  return (
    <StyledListItem
      height={48}
      alignItems="center"
      onClick={onClick}
      gap={8}
    >
      <Text fontSize={14} fontWeight="semibold">{relationship.name}</Text>
      <Flex grow={1} />
      <Chip variant="dark" label={count} />
      <Icon name="arrow-right" size={12} style={{ right: -14, position: 'relative' }} />
    </StyledListItem>
  )
}

const RENDERER_MAP: Record<keyof typeof DisplayType, any> = {
  [DisplayType.CHIP]: ChipRenderer,
  [DisplayType.CODE]: CodeRenderer,
  [DisplayType.CURRENCY]: CurrencyRenderer,
  [DisplayType.DATE_TIME]: DateTimeRenderer,
  [DisplayType.DURATION]: DurationRenderer,
  [DisplayType.EMBEDDED]: EmbeddedRenderer,
  [DisplayType.LINK]: LinkRenderer,
  [DisplayType.PHONE_NUMBER]: PhoneNumberRenderer,
  [DisplayType.PLAIN_TEXT]: PlainTextRenderer,
  [DisplayType.REFERENCE]: ReferenceRenderer,
  [DisplayType.RENDERED_HTML]: CodeRenderer,
  [DisplayType.RENDERED_MARKDOWN]: CodeRenderer,
  [DisplayType.SENSITIVE_TEXT]: GenericRenderer,
  [DisplayType.SWITCH]: SwitchRenderer,
  [DisplayType.DOCUMENT]: FileRenderer,
  [DisplayType.FILE]: FileRenderer,
  [DisplayType.IMAGE]: MediaRenderer,
  [DisplayType.AUDIO]: MediaRenderer,
  [DisplayType.VIDEO]: MediaRenderer,
  [DisplayType.MEDIA]: MediaRenderer,
  [DisplayType.NUMERIC]: NumberRenderer
}

const getRenderer = (displayType: DisplayType) => (RENDERER_MAP[displayType] || PlainTextRenderer)

function DetailsBlock({
  asFragment,
  blockRef,
  resourceId,
  operationId,
  parameters,
  rows = [],
  preprocess,
  heading,
  recordId,
  record,
  identifier,
  ...other
}: DetailsBlockProps) {
  const worker = React.useMemo(() => new Worker('/preprocess.worker.js'), [])
  const { switcher } = useDashboardViewContext()
  const targetEnvironment = switcher?.data.environment?.id
  const {
    data: { relationshipsList = [] } = {},
    loading: relationshipsLoading
  } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const { openView } = useViewDispatch()

  const {
    data: { internalFetchRecord: fetchedRecord } = {},
    loading,
    error
  } = useInternalFetchRecordQuery({
    variables: {
      input: {
        preview: true,
        targetEnvironment,
        resourceId,
        arguments: {
          id: recordId
        }
      }
    },
    skip: !resourceId || !recordId
  })

  const { blockPropertiesState, updateBlockProperties } = useDashboard()

  const [ processedRecord, setProcessedRecord ] = React.useState(fetchedRecord)

  React.useEffect(() => {
    if (!preprocess) {
      if (fetchedRecord) {
        setProcessedRecord(fetchedRecord)
        updateBlockProperties({
          [identifier]: {
            record: fetchedRecord
          }
        })
      }
      return
    }

    worker.postMessage({
      code: preprocess,
      data: JSON.stringify(fetchedRecord)
    })

    worker.onmessage = ({ data: { result } }) => {
      const parsedRecord = JSON.parse(result)
      setProcessedRecord(parsedRecord)
      updateBlockProperties({
        [identifier]: {
          record: parsedRecord
        }
      })
      return () => worker.terminate()
    }
  }, [ worker, preprocess, fetchedRecord, identifier, updateBlockProperties ])

  const { data: { resource } = {} } = useResourceQuery({
    variables: {
      id: resourceId
    },
    skip: !resourceId
  })
  const {
    data: { attributesList: attributes = [] } = {},
    loading: attributesLoading
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: ATTRIBUTES_LIST_LIMIT
    },
    skip: !resourceId
  })

  const openRelatedView = (relationship: Relationship) => openView({
    title: relationship.target?.name || 'Resource',
    component: RelatedResourceRecordsView,
    style: RelatedResourceRecordsView.defaultStyle,
    params: {
      resource: relationship.target,
      switcher,
      relationship,
      record: fetchedRecord!
    }
  })

  const blockProperties = useRecoilValue(blockPropertiesState)
  const {
    data: { executeQueryOperation: operationRecord } = {},
    loading: executeOperationLoading,
    error: executeOperationError
  } = useExecuteOperationQuery({
    context: blockProperties,
    operationId,
    arguments: parameters,
    targetEnvironment
  })

  React.useEffect(() => {
    if (operationRecord) {
      updateBlockProperties({
        [identifier]: {
          record: operationRecord
        }
      })
    }
  }, [ identifier, operationRecord, updateBlockProperties ])

  const {
    activeLocales,
    defaultLocale,
    loading: localesLoading
  } = useActiveLocales()

  const [ _, setLocaleIndex ] = React.useState(0)

  const renderDetails = (currentLocale?: Locale) => (
    <Flex direction="column" gap={24}>
      {heading && <Text fontWeight="bold">{heading}</Text>}
      <SectionLoader
        data={(processedRecord || operationRecord || record) && (!resourceId || attributes)}
        loading={
          relationshipsLoading
          || attributesLoading
          || loading
          || executeOperationLoading
          || localesLoading
        }
        error={error || executeOperationError}
      >
        {rows.length ? (
          <Flex direction="column" gap={16}>
            {rows.map((row: any) => {
              if (row.is_hidden) return null

              const attr = row.attribute && attributes.find((a) => a.id === row.attribute)
              const attributeName = [ 'data', camelCase(attr?.identifier), currentLocale?.identifier ].filter(Boolean).join('.')

              const data = attr
                ? get((processedRecord || record), attributeName)
                : JSONParseOr(safeParseLiquid(row.value, { record: operationRecord }))

              const Renderer = getRenderer(attr?.displayType || row.display_type)
              const displaySettings = { ...(attr || {}), ...(row.display_type_settings || {}), fontWeight: 'semibold' }

              return (
                <Flex direction="column" gap={10} key={attr?.id || row.label}>
                  <Text fontSize={12} color="dark500" textTransform="uppercase">{row.label || attr?.name}</Text>
                  <ErrorBoundary
                    fallback={<GenericRenderer displaySettings={displaySettings} data={data} />}
                    onError={console.error}
                  >
                    <Renderer attribute={attr} displaySettings={displaySettings} data={data} />
                  </ErrorBoundary>
                </Flex>
              )
            })}
          </Flex>
        ) : !!attributes.length && (
          <Flex direction="column" gap={16}>
            {attributes.map((attr) => {
              const attributeName = [ 'data', camelCase(attr?.identifier), currentLocale?.identifier ].filter(Boolean).join('.')
              const data = get((processedRecord || record), attributeName)
              const Renderer = getRenderer(attr.displayType as DisplayType)
              const displaySettings = { ...(attr.displayTypeSettings || {}), fontWeight: 'semibold' }

              return (
                <Flex direction="column" gap={10} key={attr.id}>
                  <Text fontSize={12} color="dark500" textTransform="uppercase">{attr.name}</Text>
                  <ErrorBoundary
                    fallback={<GenericRenderer displaySettings={displaySettings} data={data} />}
                  >
                    <Renderer attribute={attr} displaySettings={displaySettings} data={data} />
                  </ErrorBoundary>
                </Flex>
              )
            })}
          </Flex>
        )}

        {!!relationshipsList.length && (
          <Flex direction="column" gap={12}>
            <Text fontWeight="bold">Related</Text>
            <List gap={8}>
              {relationshipsList.map((relationship) => (
                <RelatedListItem
                  key={relationship.id}
                  record={(processedRecord || record) as CustomRecord}
                  relationship={relationship as Relationship}
                  onClick={() => openRelatedView(relationship as Relationship)}
                />
              ))}
            </List>
          </Flex>
        )}
      </SectionLoader>
    </Flex>
  )

  const detailsBlock = resourceId && resource?.isTranslatable && activeLocales.length > 1
    ? (
      <StyledTabsWrapper direction="column">
        <Tabs
          onChange={setLocaleIndex}
        >
          {activeLocales.map((locale, index) => (
            <StyledTab
              index={index}
              key={locale.identifier}
              label={locale.name}
              background="light"
              alwaysMounted
            >
              {renderDetails(locale)}
            </StyledTab>
          ))}
        </Tabs>
      </StyledTabsWrapper>
    ) : renderDetails(operationId ? undefined : defaultLocale)

  if (asFragment) return detailsBlock

  return (
    <Block direction="column" masonryItemRef={blockRef} {...other}>
      {detailsBlock}
    </Block>
  )
}

export default DetailsBlock

export type { DetailsBlockProps }

export { getRenderer, RENDERER_MAP }
