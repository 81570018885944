import React, { useContext } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import * as mixins from 'styles/mixins'
import AddBlockMenu from 'components/menus/AddBlockMenu'
import Block from './Block'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { PopoverContainer } from 'components/popover'
import { styled } from 'styles/stitches'
import { Views } from 'components/dashboardEditor/constants'

const hoverStyles = {
  borderColor: 'dark900',
  '& > [data-icon]': {
    backgroundColor: 'dark900',
    color: 'light100'
  },

  '& > [data-label]': {
    color: 'dark900'
  }
}

const StyledAddBlockButton = styled(Flex, {
  ...mixins.transition('simple'),

  cursor: 'pointer',
  height: 80,
  border: '2px dashed dark100',
  borderRadius: 6,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  color: 'dark100',

  '& > [data-icon]': {
    ...mixins.transition('simple'),
    borderRadius: 5,
    color: 'dark100'
  },

  '& > [data-label]': {
    ...mixins.transition('simple'),
    color: 'dark500'
  },

  '&:hover': hoverStyles,

  variants: {
    isDraggingOver: {
      true: {},
      false: {}
    },
    isAdding: {
      true: {},
      false: {}
    },
    isDragging: {
      false: {
        display: 'flex'
      },
      true: {
        display: 'none'
      }
    },
    alwaysVisible: {
      true: {
        opacity: 1
      },
      false: {
        opacity: 0,
        '&:hover': {
          opacity: 1
        }

      }
    }
  }
})

StyledAddBlockButton.compoundVariant({
  alwaysVisible: true,
  isAdding: true
}, {
  display: 'flex',
  opacity: 1,
  ...hoverStyles
})

StyledAddBlockButton.compoundVariant({
  alwaysVisible: false,
  isAdding: true
}, {
  '&:hover': {
    display: 'flex',
    opacity: 1,
    ...hoverStyles
  }
})

StyledAddBlockButton.compoundVariant({
  isDraggingOver: true,
  isAdding: true
}, {
  display: 'flex',
  opacity: 1,
  ...hoverStyles
})

const AddBlockButton = ({ id, label, alwaysVisible }: any) => {
  const {
    draggedBlockState,
    draggingOverBlockIdState,
    dashboardEditorState
  } = useDashboard()
  const [ draggedBlock ] = useRecoilState(draggedBlockState)
  const draggingOverBlockId = useRecoilValue(draggingOverBlockIdState)

  const isAddColumnOrTab = id.startsWith('blockId:')
  const isAdding = draggedBlock?.type && !draggedBlock?.id

  const { target: dashboardEditorActiveView } = useRecoilValue(dashboardEditorState)
  const isInspecting = dashboardEditorActiveView === Views.EDIT_COMPONENT
  const { editMode } = useContext(DashboardContext)!

  if (isInspecting || !editMode) {
    return null
  }

  return (
    <PopoverContainer
      placement="right-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ 0, 6 ]
          }
        }
      ]}
    >
      {({ isActive, ref, closePopover, openPopover, ...otherToggleProps }) => (
        <Block
          {...otherToggleProps}
          id={id}
          hideActionCard
          name="Add Block"
          width={{ md: '100%' }}
        >
          <StyledAddBlockButton
            direction="column"
            grow={1}
            gap={6}
            isDragging={!!draggedBlock?.id && !isAddColumnOrTab}
            isAdding={(isAdding && (!isAddColumnOrTab || draggingOverBlockId === id)) || isActive}
            isDraggingOver={[ 'ADD_BLOCK', 'DASHBOARD' ].includes(draggingOverBlockId!) && (!isAddColumnOrTab || draggingOverBlockId === id)}
            alwaysVisible={alwaysVisible}
          >
            <Icon
              data-icon
              name="add-outline-round"
              size={20}
              ref={ref}
            />
            <Text fontWeight="bold" fontSize={12} data-label>{label}</Text>
          </StyledAddBlockButton>
        </Block>
      )}
      {(popoverProps) => (
        <AddBlockMenu
          {...popoverProps}
        />
      )}
    </PopoverContainer>
  )
}

export default AddBlockButton
