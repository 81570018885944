import get from 'lodash/get'
import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import convertToArray from 'lib/convertToArray'
import isIdentifier from 'lib/formValidators/isIdentifier'
import { FieldIdentifier } from './Field'
import type { AttributesListQuery, ResourceFragmentFragment as ResourceFragment } from 'generated/schema'

const nullableFieldIdentifiers = [
  FieldIdentifier.CHECKBOX,
  FieldIdentifier.DATE,
  FieldIdentifier.DURATION,
  FieldIdentifier.DROPDOWN,
  FieldIdentifier.JSON,
  FieldIdentifier.LOCATION,
  FieldIdentifier.MEDIA,
  FieldIdentifier.FILE,
  FieldIdentifier.NUMBER,
  FieldIdentifier.PHONE,
  FieldIdentifier.SWITCH,
  FieldIdentifier.TEXT,
  FieldIdentifier.MARKDOWN
]

const filterableFieldIdentifiers = [
  FieldIdentifier.CHECKBOX,
  FieldIdentifier.DATE,
  FieldIdentifier.DROPDOWN,
  FieldIdentifier.NUMBER,
  FieldIdentifier.PHONE,
  FieldIdentifier.SLUG,
  FieldIdentifier.SWITCH,
  FieldIdentifier.TEXT,
  FieldIdentifier.UID
]

const searchableFieldIdentifier = [
  FieldIdentifier.DROPDOWN,
  FieldIdentifier.NUMBER,
  FieldIdentifier.PHONE,
  FieldIdentifier.TEXT
]

const allowedTitleFieldIdentifiers = [
  FieldIdentifier.NUMBER,
  FieldIdentifier.TEXT,
  FieldIdentifier.DROPDOWN
]

const fieldsWithTheirOwnRepeatedBehavior = [
  FieldIdentifier.FILE
]

const orderableFieldIdentifiers = [
  FieldIdentifier.NUMBER,
  FieldIdentifier.TEXT,
  FieldIdentifier.DATE,
  FieldIdentifier.SWITCH
]

enum ResolutionKind {
  COMPUTED = 'COMPUTED',
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE'
}

enum ValidationKind {
  EXCLUSION = 'EXCLUSION',
  INCLUSION = 'INCLUSION',
  LENGTH = 'LENGTH',
  MATCHES = 'MATCHES',
  NOT_MATCHES = 'NOT_MATCHES',
  PRESENCE = 'PRESENCE',
  UNIQUENESS = 'UNIQUENESS',
  COUNT = 'COUNT', // for array
  FILE_SIZE = 'FILE_SIZE',
  FILE_TYPE = 'FILE_TYPE',
  FILE_DIMENSIONS = 'FILE_DIMENSIONS',
  FILE_DURATION = 'FILE_DURATION'
}

enum DisplayType {
  CHIP = 'CHIP',
  CODE = 'CODE',
  CURRENCY = 'CURRENCY',
  DATE_TIME = 'DATE_TIME',
  DURATION = 'DURATION',
  EMBEDDED = 'EMBEDDED',
  IMAGE = 'IMAGE',
  LINK = 'LINK',
  MEDIA = 'MEDIA',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PLAIN_TEXT = 'PLAIN_TEXT',
  REFERENCE = 'REFERENCE',
  RENDERED_HTML = 'RENDERED_HTML',
  RENDERED_MARKDOWN = 'RENDERED_MARKDOWN',
  SENSITIVE_TEXT = 'SENSITIVE_TEXT',
  SWITCH = 'SWITCH',
  DOCUMENT = 'DOCUMENT',
  FILE = 'FILE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  NUMERIC = 'NUMERIC'
}

const displayTypesWithSettings = [
  DisplayType.CHIP,
  DisplayType.CODE,
  DisplayType.CURRENCY,
  DisplayType.DATE_TIME,
  DisplayType.DURATION,
  DisplayType.EMBEDDED,
  DisplayType.LINK,
  DisplayType.PHONE_NUMBER,
  DisplayType.PLAIN_TEXT,
  DisplayType.REFERENCE,
  DisplayType.RENDERED_HTML,
  DisplayType.RENDERED_MARKDOWN,
  DisplayType.SENSITIVE_TEXT,
  DisplayType.SWITCH
]

const fieldTypesWithSettings = [
  FieldIdentifier.CHECKBOX,
  FieldIdentifier.DATE,
  FieldIdentifier.DROPDOWN,
  FieldIdentifier.DURATION,
  FieldIdentifier.EMAIL,
  FieldIdentifier.MARKDOWN,
  FieldIdentifier.NUMBER,
  FieldIdentifier.PASSWORD,
  FieldIdentifier.RADIO,
  FieldIdentifier.REFERENCE,
  FieldIdentifier.SWITCH,
  FieldIdentifier.TEXT,
  FieldIdentifier.FILE
]

const RESERVED_ATTRIBUTES = [ 'id', 'environment-id', 'parent-id', 'position', 'created-at', 'updated-at', 'deleted-at' ]

type AttributesList = AttributesListQuery['attributesList']

type ResolveTitleOptions = {
  activeLocale?: string,
  defaultLocale?: string,
  fallbackTitle?: string
}

class Attribute extends BaseModel {
  static schema = object({
    fieldType: string().required(),
    identifier: isIdentifier(),
    name: string().required(),
    dataTypeId: string().required()
  })

  static getTitleField = (attributesList: AttributesList, resource?: ResourceFragment) => {
    const actualTitleField = resource?.titleAttributeId
      ? attributesList.find((field) => field.id === resource.titleAttributeId)
      : undefined

    let potentialTitleField: AttributesList[number] | undefined

    if (!actualTitleField) {
      for (let i = 0; i < attributesList.length; i++) {
        const field = attributesList[i]
        if (Attribute.isTitleableField(field.fieldType as FieldIdentifier)) {
          potentialTitleField = field
          break
        }
      }
    }

    return { actualTitleField, potentialTitleField }
  }

  static resolveTitle = (
    attributesList?: AttributesList,
    data?: any,
    resource?: ResourceFragment,
    options?: ResolveTitleOptions
  ) => {
    if (!attributesList || !data || !resource) {
      return ''
    }

    const {
      activeLocale = 'en_US',
      defaultLocale = 'en_US',
      fallbackTitle
    } = options || {}

    const {
      actualTitleField, potentialTitleField
    } = Attribute.getTitleField(attributesList, resource)

    const titleField = actualTitleField || potentialTitleField

    if (!titleField && fallbackTitle) {
      return fallbackTitle
    }

    const locale = titleField?.isTranslatable ? activeLocale : defaultLocale

    const value = titleField ? get(data, `${titleField.identifier}.${locale}`) : ''

    const title = convertToArray(value)

    return title.filter(Boolean).join(', ')
  }

  static isTitleableField = (fieldType: FieldIdentifier) => (
    allowedTitleFieldIdentifiers.includes(fieldType)
  )

  static isOrderableField = (fieldType?: FieldIdentifier) => (
    fieldType && orderableFieldIdentifiers.includes(fieldType)
  )

  static isFilterableField = (fieldType?: FieldIdentifier) => (
    fieldType && filterableFieldIdentifiers.includes(fieldType)
  )

  static isNullableField = (fieldType?: FieldIdentifier) => (
    fieldType && nullableFieldIdentifiers.includes(fieldType)
  )

  static isSearchableField = (fieldType?: FieldIdentifier) => (
    fieldType && searchableFieldIdentifier.includes(fieldType)
  )

  static hasCustomRepeatedBehavior = (fieldType?: FieldIdentifier) => (
    fieldType && fieldsWithTheirOwnRepeatedBehavior.includes(fieldType)
  )

  static hasDisplayTypeSettings = (displayType?: DisplayType) => (
    displayType && displayTypesWithSettings.includes(displayType)
  )

  static hasFieldTypeSettings = (fieldType?: FieldIdentifier) => (
    (fieldType && fieldTypesWithSettings.includes(fieldType))
    || fieldType === FieldIdentifier.NESTED_RELATIONS
  )
}

export default Attribute

export {
  DisplayType,
  RESERVED_ATTRIBUTES,
  ResolutionKind,
  ValidationKind
}
